<template>
    <div class="modal-basic">
        <div class="exit" @click="$emit('close')">
            <i class="material-icons">close</i>
        </div>
        <div class="body">
            <div class="header m-b-16">
                <p class="f-16 spoqa-f-medium m-b-8">내 포인트</p>
                <p>포인트 이렇게 활용할 수 있어요!</p>
            </div>
            <div class="items">
                <div class="item" v-for="item in items" :key="item.id">
                    <div class="name spoqa-f-medium" v-html="item.name" />
                    <div class="point">
                        <img :src="require('@/assets/images/point_icon.png')" alt="" />
                        <span v-html="item.point" />
                    </div>
                </div>
            </div>
            <div class="desc m-b-16">
                *언제나 내가 먼저 이성의 프로필을 받아보고, 내가 수락한 이성에게만 내 프로필을 보여주는 강력한
                프라이버시 보호 기능입니다.
            </div>
        </div>
        <BottomButton @click="onClickBtn" label="무료로 포인트 받기" :overlay="true" :nonFixed="true" />
    </div>
</template>

<script>
export default {
    name: 'ModalHowToUsePoint',
    computed: {
        items() {
            return [
                {
                    id: 1,
                    name: '프로필 1명 더 받기',
                    point: 20,
                },
                {
                    id: 2,
                    name: '시크릿모드 4주',
                    point: 400,
                },
            ]
        },
    },
    methods: {
        onClickBtn() {
            this.$emit('close', true)
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    width: 90%;
    margin: 0 auto;

    .exit {
        display: flex;
        flex-direction: row-reverse;
    }
    .body {
        padding: 0;
    }
    .item {
        padding: 16px;
        background: $grey-01;
        border-radius: 12px;
        margin-bottom: 8px;
        font-size: 16px;
        @include between;

        .point {
            img {
                width: 14px;
                height: auto;
                margin-right: 2px;
            }
            color: $blue-primary;
            @include center;
        }
    }
    .desc {
        color: $grey-07;
        text-align: left;
    }
}
</style>
